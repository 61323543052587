<template>
  <div>
    <b-container fluid style="background-color: #00C782;">
      <b-row class="justify-content-center align-items-center min-vh-100">
        <b-container class="text-center text-white py-5" style="background: rgba(0, 0, 0, 0.6); border-radius: 15px; max-width: 800px;">
          <div class="mx-auto">
            <b-row>
              <b-col cols="12" class="mb-5">
                <h1 class="display-4 font-weight-bold" style="color: #FFFFFF;">
                  {{ $t("register_organization.thanks") }} 
                </h1>
                <h3 style="color: #00C782;">
                  {{ $t("register_organization.welcome_reachingapp") }}
                </h3>
              </b-col>

              <b-col cols="12" class="mb-3" v-if="getEmail">
                <p class="lead" style="font-size: 1.3rem;">
                  <span>{{ $t("register_organization.confirmation_email_sent") }} {{ getEmail }}</span>
                </p>
              </b-col>

              <b-col cols="12" class="mb-4">
                <p class="p-0 m-0" style="color: #B0BFBF; font-size: 1.25rem;">
                  {{ $t("register_organization.confirmation_email_spam") }}
                </p>
              </b-col>

              <b-col cols="12">
                <p style="color: #B0BFBF;font-size: 1.25rem;">
                  {{ $t("register_organization.confirmation_email_questions") }}
                </p>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // No need for background image data
    }
  },
  computed: {
    getEmail() {
      return localStorage.getItem("register-now-email")
    },
  },
  beforeDestroy() {
    localStorage.removeItem("register-now-email")
  },
}
</script>

<style scoped>
h1 {
  color: #FFFFFF;
}
h3 {
  color: #00C782;
}
p {
  color: #B0BFBF;
}
</style>
